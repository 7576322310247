import React from 'react'
import {Link} from 'react-router-dom';
import "../css/Elements.css";

function Navbar() {

  return (
    <div className="navbar-container">
        <div className="signature">
            <Link to='/'><h2>BK.</h2></Link>
        </div>
        <div className="menu">
            <Link to='/'                                                                    id="nav">Home</Link>
            <Link to='https://github.com/brann25' target="_blank" rel="noopener noreferrer" id="nav">Github</Link>
            <Link to='https://drive.google.com/file/d/1Ckly7GBosLKLaOe2eo8LTJqBkfbSFxH_/view?usp=sharing' 
                                                  target="_blank" rel="noopener noreferrer" id="nav">Resume</Link>
            <Link to='/about'                                                               id="nav">About</Link>
        </div>
    </div>
  )
}

export default Navbar