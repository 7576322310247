import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { scrollThere } from './functions/scrollThere';
// CSS
import './css/Projects.css'
import './css/Research.css'
import './css/DesignSystem.css'
// COMPO
import HeroBanner from './elements/HeroBanner'
import Breadcrumb from './elements/Breadcrumb';
import ProjectBrief from './elements/ProjectBrief';
import ProjectComponentA from './elements/ProjectComponentA';
import ProjectComponentA2 from './elements/ProjectComponentA2';
import ProjectComponentB from './elements/ProjectComponentB';
import ProjectComponentC from './elements/ProjectComponentC';
import ProjectComponentC2 from './elements/ProjectComponentC2';
import ProjectComponentE from './elements/ProjectComponentE';
import ReserchBlockA from './elements/ReserchBlockA';
import ReserchBlockB from './elements/ReserchBlockB';
import ReserchBlockC from './elements/ReserchBlockC';
import ReserchBlockD from './elements/ReserchBlockD';
import Footer     from './elements/Footer';
import ProjectNavigator from './elements/ProjectNavigator';
import CardMobile from './elements/CardMobile'

// IMG
import hn_banner from './img/homepage/projectHanover/Home.png'
import hn_layout1 from './img/homepage/projectHanover/hn_layout1.png'
import hn_color1 from './img/homepage/projectHanover/hn_color_ratio1.png'
import hn_color2 from './img/homepage/projectHanover/hn_color_ratio2.png'
import hn_color3 from './img/homepage/projectHanover/hn_color_ratio3.png'
import hn_icons from './img/homepage/projectHanover/hn_color_icons.png'
import hn_icon1 from './img/homepage/projectHanover/hn_color_icon1.png'
import hn_color_desktop_layout1 from './img/homepage/projectHanover/hn_color_desktop_layout1.png'
import hn_color_desktop_layout2 from './img/homepage/projectHanover/hn_color_desktop_layout2.png'
import hn_color_desktop_layout3 from './img/homepage/projectHanover/hn_color_desktop_layout3.png'
import hn_color_desktop_layout4 from './img/homepage/projectHanover/hn_color_desktop_layout4.png'

import amigo_banner from './img/homepage/projectAmigo/amigo_banner.png'
import amigo_icon1 from './img/homepage/projectAmigo/amigo_Icon-trip.png'
import amigo_icon2 from './img/homepage/projectAmigo/amigo_Icon-budget.png'
import amigo_icon3 from './img/homepage/projectAmigo/amigo_Icon-fashion.png'
import amigo_icon4 from './img/homepage/projectAmigo/amigo_Icon-inspiration.png'
import amigo_icon5 from './img/homepage/projectAmigo/amigo_Icon-bookmark.png'
import amigo_icon6 from './img/homepage/projectAmigo/amigo_Icon-chats.png'
import amigo_c_a   from './img/homepage/projectAmigo/amigo_budget-page.png'
import amigo_groupset   from './img/homepage/projectAmigo/amigo_group-set.png'
import amigo_overall   from './img/homepage/projectAmigo/amigo_overall.png'
import amigo_research from './img/homepage/projectAmigo/amigo_research.png'
import amigo_flows from './img/homepage/projectAmigo/amigo_flows.png'
import amigo_layout from './img/homepage/projectAmigo/amigo-layout.png'
import amigo_set1 from './img/homepage/projectAmigo/amigo-feature1.png'
import amigo_set2 from './img/homepage/projectAmigo/amigo-feature2.png'
import amigo_set3 from './img/homepage/projectAmigo/amigo-feature3.png'
import Navbar from './elements/Navbar';

function ProjectHanover() {

    // linear-gradient(82deg, rgba(255,255,255,1) 24%, rgba(86,134,214,1) 63%)

  window.scrollTo(0,0);
  return (
    <>
    <div className="desktop">
      <div id="project-top">
        <HeroBanner 
        projectTitle="Hanover DS"
        textBlockMarginTop="120px"
        projectHeadline="A Design system that help build a stronger identity with consistent and unified visuals, interaction, and experiences for Fintech products."
        bannerImage={hn_banner}
        bannerWidth="140%"
        HeroBGcolor="linear-gradient(158deg, #E1E9FF 0%, white 100%)"
        borderR="0.8em 0 0 0.8em"
        bannerChip1="Design System Creative"
        bannerChip2="2 Weeks"
        bannerChip3="Lead Design"
        bannerChip4="Desktop | Tablet | Mobile"
        bgColor="white"
        color="rgba(255,255,255,0.8)"
        tagBG="#E6E6E6"
        tagCl="#292929"
        spinner="none"
        />
      </div>
      <Breadcrumb 
          top="project-top"
          section1Lable="Introduction"
          section2Lable="Color & Visuals"
          section3Lable="Typography"
          section4Lable="Layout & Components"
          section1="Introduction"
          section2="Product"
          section3="Prototype"
          section4="Research"
          currentPage="Hanover DS"
        />
    {/* BRIEF */}
     <div className="stories">
      <div className="story" id="Introduction">
        <ProjectBrief 
        challenges="Each product requires different interactions to achieve its goal for users; consequently, more variation and limitations it needed for each new requirement. The problems erode creativity and emphasize the roadblock that prevents teams and users from achieving their goals."
        roles="We researched issues with existing systems, and then designed and created a solution to simply enhance them. By providing various components and elements that are flexible and respond well to multiple use-case scenarios, Hanover DS works to ensure the consistent quality of your product."
        tools="Figma | React.js | Adobe Color | Paper and Pen"
        />
      </div>
      <div className="ds-intro">
        <h2 id="ds-topic">Design System and Documentation</h2>
        <p>Building a well consistent designed product can be challenging, especially when requirements from end-users and other stakeholders direct their needs in different ways. Hanover offers simple guidlines to manage and create a product that is coherent, unified, and stay under the same big umbrealla of identity. Here are some highlight from Hanover.</p>
        <div className="amigo-finding">
              <div id="amigo-finding-no">
                <h4 style={{backgroundColor:'#E6E6E6', color:'#292929'}}>1</h4>
                <p>Comprehensive guidlines from color palette, visual elements, typography, and layout.</p>
              </div>
              <div id="amigo-finding-no">
                <h4 style={{backgroundColor:'#E6E6E6', color:'#292929'}}>2</h4>
                <p>Flexible and easily adaptable to create different varient responding your stakholders' needs.</p>
              </div>
              <div id="amigo-finding-no">
                <h4 style={{backgroundColor:'#E6E6E6', color:'#292929'}}>3</h4>
                <p>Accessible guide for your users, because we are pusing towards a kind world for everyone.</p>
              </div>
          </div>
      </div>
      {/* COLOR */}
      <div className="story" id="Product">
        <h3 id="ds-title">Color Palette</h3>
        <div className="color-palette-container">
          {/* TOP COLOR DESCRIBTION */}
          <div className="color-palette-wrapper" style={{marginTop:'10px', display:'block', width:'600px'}}> 
            <div className='color-palette-describtion'>
              <h4 style={{fontWeight:'800', marginBottom:'20px'}}>Primary Palette</h4>
              <p><b style={{fontWeight:'600'}}>When to use:</b> Primarily use this as a major selection to maintain consistency throughout. You may select variations of different colors outside this scope, but with minimal usage</p>
            </div>
          </div>
          <div className="color-palette-main-wrapper" style={{marginTop:'40px'}}>
            <div className="color-palette-main" style={{backgroundColor:'#1557FF', color:'white'}}>
              <h2>Santorini</h2>
              <p>#1557FF</p>
            </div>
            <div className="color-palette-main" style={{backgroundColor:'#414141', color:'white'}}>
              <h2>Charcol</h2>
              <p>#414141</p>
            </div>
            <div className="color-palette-main" style={{backgroundColor:'#F06C57', color:'#292929'}}>
              <h2>Cherry</h2>
              <p>#F06C57</p>
            </div>
            <div className="color-palette-main" style={{backgroundColor:'#F08364', color:'#292929'}}>
              <h2>Cara</h2>
              <p>#F08364</p>
            </div>
            <div className="color-palette-main" style={{backgroundColor:'#14325C', color:'white'}}>
              <h2>Current</h2>
              <p>#14325C</p>
            </div>
            <div className="color-palette-main" style={{backgroundColor:'#3181FF', color:'#292929'}}>
              <p>#3181FF</p>
            </div>
            <div className="color-palette-main" style={{backgroundColor:'#5C9BFF', color:'#292929'}}>
              <p>#5C9BFF</p>
            </div>
            <div className="color-palette-main" style={{backgroundColor:'#7BACFF', color:'#292929'}}>
              <p>#7BACFF</p>
            </div>
            <div className="color-palette-main" style={{backgroundColor:'#9CC2FF', color:'#292929'}}>
              <p>#9CC2FF</p>
            </div>
            <div className="color-palette-main" style={{backgroundColor:'#B4CEFF', color:'#292929'}}>
              <p>#B4CEFF</p>
            </div>
            <div className="color-palette-main" style={{backgroundColor:'#CBDEFF', color:'#292929'}}>
              <p>#CBDEFF</p>
            </div>
            <div className="color-palette-main" style={{backgroundColor:'#E1E9FF', color:'#292929'}}>
              <p>#E1E9FF</p>
            </div>
            <div className="color-palette-main" style={{backgroundColor:'#F0C1B7', color:'#292929'}}>
              <p>#F0C1B7</p>
            </div>
            <div className="color-palette-main" style={{backgroundColor:'#F0CAC1', color:'#292929'}}>
              <p>#F0CAC1</p>
            </div>
          </div>
          {/* COLOR VARIATION DESCRIBTION */}
          <div className='color-palette-describtion' style={{marginTop:'60px', marginBottom:'10px'}}>
              <h4 style={{fontWeight:'800', marginBottom:'20px'}}>Color Variation</h4>
              <p style={{width:'40%'}}> <b style={{fontWeight:'600'}}>How to apply:</b> consider using primary color first for general purposes, add accent colors for status, warning, or notification. </p>
              <div className="color-sample-meaning">
                <p>Sample Color and meaning:</p> 
                <div className="color-samples" style={{background:'#ED4D47', color:'#292929'}}><p>Alert</p></div>
                <div className="color-samples" style={{background:'#F58522', color:'#292929'}}><p>Warning</p></div>
                <div className="color-samples" style={{background:'#F0D826', color:'#292929'}}><p>Opportunity</p></div>
                <div className="color-samples" style={{background:'#25D976', color:'#292929'}}><p>Success</p></div>
                <div className="color-samples" style={{background:'#21E3ED', color:'#292929'}}><p>Pending</p></div>
                <div className="color-samples" style={{background:'#3465E0', color:'white'}}><p>Done</p></div>
              </div>
          </div>
          {/* COLOR VARIATION */}
          <div className="color-palette-wrapper"> 
            <div className="color-varient-sets">
              <div className="color-varients">
                {/* RED */}
                <div className="color-varient" style={{backgroundColor:'#872C29', color:'white'}}>
                  <p>#872C29</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#C23F3A', color:'white'}}>
                  <p>#C23F3A</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#ED4D47', color:'#292929'}}>
                  <p>#ED4D47</p>
                </div>
              </div>
              {/* BRICK */}
              <div className="color-varients">
                <div className="color-varient" style={{backgroundColor:'#944622', color:'white'}}>
                  <p>#944622</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#C45D2D', color:'#292929'}}>
                  <p>#C45D2D</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#EB6F36', color:'#292929'}}>
                  <p>#EB6F36</p>
                </div>
              </div>
              <div className="color-varients">
                <div className="color-varient" style={{backgroundColor:'#A85A17', color:'white'}}>
                  <p>#A85A17</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#CF701D', color:'#292929'}}>
                  <p>#CF701D</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#F58522', color:'#292929'}}>
                  <p>#F58522</p>
                </div>
              </div>
            </div>
            {/* YELLOW-GOLD */}
            <div className="color-varient-sets">
              <div className="color-varients">
                <div className="color-varient" style={{backgroundColor:'#998B18', color:'#292929'}}>
                  <p>#998B18</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#CFBA21', color:'#292929'}}>
                  <p>#CFBA21</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#F0D826', color:'#292929'}}>
                  <p>#F0D826</p>
                </div>
              </div>
              {/* OLIVE */}
              <div className="color-varients">
                <div className="color-varient" style={{backgroundColor:'#58991C', color:'#292929'}}>
                  <p>#58991C</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#74C924', color:'#292929'}}>
                  <p>#74C924</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#84E629', color:'#292929'}}>
                  <p>#84E629</p>
                </div>
              </div>
              {/* GREEN */}
              <div className="color-varients">
                <div className="color-varient" style={{backgroundColor:'#18994D', color:'white'}}>
                  <p>#18994D</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#21C269', color:'#292929'}}>
                  <p>#21C269</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#25D976', color:'#292929'}}>
                  <p>#25D976</p>
                </div>
              </div>
            </div>
            <div className="color-varient-sets">
              {/* CYAN */}
              <div className="color-varients">
                <div className="color-varient" style={{backgroundColor:'#159399', color:'white'}}>
                  <p>#159399</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#1CBEC7', color:'#292929'}}>
                  <p>#1CBEC7</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#21E3ED', color:'#292929'}}>
                  <p>#21E3ED</p>
                </div>
              </div>
              {/* BLUE */}
              <div className="color-varients">
                <div className="color-varient" style={{backgroundColor:'#1E5C99', color:'white'}}>
                  <p>#1E5C99</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#2879C9', color:'white'}}>
                  <p>#2879C9</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#2E8BE8', color:'white'}}>
                  <p>#2E8BE8</p>
                </div>
              </div> 
              {/* DEEP BLUE */}
              <div className="color-varients">
                <div className="color-varient" style={{backgroundColor:'#1A3270', color:'white'}}>
                  <p>#1A3270</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#264AA6', color:'white'}}>
                  <p>#264AA6</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#3465E0', color:'white'}}>
                  <p>#3465E0</p>
                </div>
              </div>
            </div>
          </div>
          {/* NEUTRAL COLOR SETS DESCRIBTION*/}
          <div className='color-palette-describtion' style={{width:'40%', marginTop:'80px'}}>
              <h4 style={{fontWeight:'800', marginBottom:'20px'}}>Neutral Color Sets</h4>
              <p> <b style={{fontWeight:'600'}}>How to apply:</b> Neutral colors bring harmony to your product. These could be used as a divider and background color.</p>
          </div>
          {/* NEUTRAL COLOR SETS*/}
          <div className="color-varient-sets" style={{marginTop:'40px'}}>
              <div className="color-varients">
                <div className="color-varient" style={{backgroundColor:'#2E2E2E', color:'white'}}>
                  <p>#2E2E2E</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#808080', color:'#292929'}}>
                  <p>#808080</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#BABABA', color:'#292929'}}>
                  <p>#BABABA</p>
                </div>
              </div>
              <div className="color-varients">
                <div className="color-varient" style={{backgroundColor:'#CFCFCF', color:'#292929'}}>
                  <p>#CFCFCF</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#E6E6E6', color:'#292929'}}>
                  <p>#E6E6E6</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#FCFCFC', color:'#292929'}}>
                  <p>#FCFCFC</p>
                </div>
              </div>
              <div className="color-varients">
                <div className="color-varient" style={{backgroundColor:'#92929C', color:'#292929'}}>
                  <p>#92929C</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#C2C2CF', color:'#292929'}}>
                  <p>#C2C2CF</p>
                </div>
                <div className="color-varient" style={{backgroundColor:'#DFDFED', color:'#292929'}}>
                  <p>#DFDFED</p>
                </div>
              </div>
          </div>
          {/* GRADIENT COLOR SETS DESCRIBTION*/}
          <div className='color-palette-describtion' style={{width:'40%', marginTop:'80px'}}>
              <h4 style={{fontWeight:'800', marginBottom:'20px'}}>Gradient Color Sets</h4>
              <p> <b style={{fontWeight:'600'}}>How to apply:</b> Gradient colors add complexity to your palette use, and this is the guideline to how it used.</p>
          </div>
          {/* GRADIENT COLOR SETS*/}
          <div className="color-gradient-sets" style={{marginTop:'40px'}}>
                <div className="color-gradient" style={{backgroundImage:'linear-gradient(315deg, #92929C 0%, #DFDFED 100%', color:'#292929'}}>
                  <p>Linear-gradient: <br/> 315deg from #92929C to #DFDFED</p>
                </div>
                <div className="color-gradient" style={{backgroundImage:'radial-gradient(circle, #92929C 0%, #DFDFED 100%)', color:'#292929'}}>
                  <p>Radial-gradient: <br/> Circle from #92929C to #DFDFED</p>
                </div>
          </div>
          {/* COLOR RATIO */}
          <div className='color-palette-describtion' style={{width:'40%', marginTop:'80px'}}>
              <h4 style={{fontWeight:'800', marginBottom:'20px'}}>Color Ratio:</h4>
              <p> <b style={{fontWeight:'600'}}>How to apply:</b> Hanover DS suggests you pick a primary color, in each variant set, the main color should consist of 60%, secondary use at 30% and 10% for accent colors.</p>
          </div>
          {/* RATIO */}
          <div className="color-varient-sets" style={{marginTop:'40px', position:'relative'}}>
              <div className="color-varients">
                <div className="color-example-ratio">
                  <img src={hn_color1} id="color-sample-image" alt="" />
                </div>
                <div className="color-example-ratio">
                  <img src={hn_color2} id="color-sample-image" alt="" />
                </div>
                <div className="color-example-ratio">
                  <img src={hn_color3} id="color-sample-image" alt="" />
                </div>
              </div>
          </div>
        </div>

      </div>
          {/* <h3 id="ds-title">Icons and Artworks</h3> */}
      <div className="icons-arts-container">
        {/* ICON-ARTS SETS*/}
        <div className="color-palette-container">
          {/* ICON-ARTS SETS DESCRIBTION*/}
          <div className="icon-container">
            <h3 id="ds-title">Icons and Artworks</h3>
            <div className="color-palette-wrapper" style={{marginTop:'10px', display:'block', width:'40%'}}> 
                <div className='color-palette-describtion'>
                  <h4 style={{fontWeight:'800', marginBottom:'20px'}}>Iconography</h4>
                  <p>To convey messages, icons help users understand the function faster. It should be placed carefully, for clickable icons not larger than 48x48px. If sitting next to the title, it should contain within the line-height of the text.</p>
                </div> 
            </div>
            <div className="icons-wrapper">
                  <div className="icons-l">
                    <h4>Icon Details</h4>
                    <div className="icon-usage1">
                      <div className="icon-usage-inner">
                      <div className="dot-rt"></div>
                      <div className="dot-rb"></div>
                      <div className="dot-lt"></div>
                      <div className="dot-lb"></div>
                      <img src={hn_icon1} style={{width:'100%', margin:'-0.2em 0 -0.1em -0.1em'}} alt="" />
                      </div>
                    </div>
                    <p style={{fontSize:'12px'}}>*Image shown above is 5X scale.</p>
                    <p style={{fontSize:'16px', textAlign:'left'}}>The paddingis 2px each side, which leave 20x20 px space for the icon.</p>
                    <div className="icon-title" style={{marginTop:'20px', border:'none', background:'#E6E6E6', borderRadius:'0.8em'}}>
                      {/* <div className="icon-button">
                        <img src={hn_icon1} style={{outline:'none'}} id="icon-sample" alt="" />
                        <p id="product-text"  style={{fontSize:'20px', fontWeight:'bold', boxShadow:'none'}}>Wallet</p>
                      </div> */}
                    </div>
                    <div className="button-wrapper" style={{display:'flex', justifyContent:'center', textAlign:'center', margin:'20px auto auto auto'}}>
                      <div className="icon-sample-button">
                        <img src={hn_icon1} style={{width:'20px', height:'20px', verticalAlign:'middle'}} />
                        <p style={{fontFamily:'"Istok Web", sans-serif', lineHeight:'20px', height:'20px', fontWeight:'bold', margin:'auto auto auto auto', fontSize:'18px', verticalAlign:'middle', paddingTop:'2px'}}>Wallet</p>
                      </div>
                    </div>
                    <p style={{fontSize:'16px', textAlign:'left'}}>The button's padding should be 0.16em at the minimum. The border-radius is 0.8em, and the gutter between text and icon should be 0.5em. or at least 12px on each side.</p>
                  </div>
                  <div className="icons-r">
                    <img src={hn_icons} style={{width:'100%'}} alt="" />
                  </div>
                </div>
          </div>
        </div>
      </div>
      {/* TYPO */}
      <div className="story" id="Prototype">
        <h3 id="ds-title">Typography</h3>
          <div className='color-palette-describtion' style={{marginBottom:'40px'}}>
              <h4 style={{fontWeight:'800', marginBottom:'20px'}}>Typeface and Paragraph</h4>
              <p style={{width:'760px'}}>Istok Web is the main typeface, for its clean and easy-to-read with homogenous terminal, tail, and ascender curves hinting luxurious and flourishing feeling. All while being San Serif which is accessible to readers. <b>Paragraphs width is limited at 760px</b> for a readability reason. <b> Each text box should have 0.5em padding to its border and at least 1em margin</b> from other elements.</p>
              <div className="tags" id="typeface-tag">
                        <p className="tag" style={{backgroundColor:'#E6E6E6'}}>Typeface: Istok Web</p>
                        <p className="tag" style={{backgroundColor:'#E6E6E6'}}>Designer: Andrey V. Panov</p>
                        <p className="tag" style={{backgroundColor:'#E6E6E6'}}>Type: San Serif</p>
                        <p className="tag" style={{backgroundColor:'#E6E6E6'}}>Google Fonts</p>
              </div>
          </div>
        <div className="typeface-display">
          <div className="typeface-each-wrapper">
                <div className="typeface-detail-box">
                  <div className="typeface-describtion">
                      <h3>Level 1</h3>
                      <p> <b>For:</b>  Topic and Generic Header displaying high categories grouping. The topic should follow with a description or features that is directly related to the text. Examples include: "Balance", "Loans", and "Helps".</p>
                      <div className="tags" id="typeface-tag">
                        <p className="tag">Size: 60px</p>
                        <p className="tag">Weight: 800</p>
                        <p className="tag">Desktop | Tablet</p>
                      </div>
                  </div>
                </div>
                <div className="typeface-detail-box">
                  <div className="typeface-textbox">
                    <div className="typeface-texts">
                      <div className="dot-rt"></div>
                      <div className="dot-rb"></div>
                      <div className="dot-lt"></div>
                      <div className="dot-lb"></div>
                      <div className="typeface-text">
                        <p style={{fontSize:'60px', fontWeight:'800'}}>Happy Life.</p>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          <div className="typeface-each-wrapper">
                <div className="typeface-detail-box">
                  <div className="typeface-describtion">
                      <h3>Level 2</h3>
                      <p> <b>For:</b> Topic and Generic Header displaying high categories grouping. The topic should follow with describtion or features that is directly related to the text. Example include: "Balance", "Loans", and "Helps".</p>
                      <div className="tags" id="typeface-tag">
                        <p className="tag">Size: 42px</p>
                        <p className="tag">Weight: 600</p>
                        <p className="tag">Desktop, Tablet</p>
                      </div>
                  </div>
                </div>
                <div className="typeface-detail-box">
                  <div className="typeface-textbox">
                    <div className="typeface-texts">
                      <div className="dot-rt"></div>
                      <div className="dot-rb"></div>
                      <div className="dot-lt"></div>
                      <div className="dot-lb"></div>
                      <div className="typeface-text">
                        <p style={{fontSize:'42px', fontWeight:'600'}}>Happy to be here.</p>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          <div className="typeface-each-wrapper">
                <div className="typeface-detail-box">
                  <div className="typeface-describtion">
                      <h3>Level 3</h3>
                      <p> <b>For:</b> Title purposes. For desktop and tablet formats, this level display smaller category topic or to define feature and function; such as "Calculator" and "Calendar". For mobile format, this level is good for header and punchline.</p>
                      <div className="tags" id="typeface-tag">
                        <p className="tag">Size: 32px</p>
                        <p className="tag">Weight: 600</p>
                        <p className="tag">All Formats</p>
                      </div>
                  </div>
                </div>
                <div className="typeface-detail-box">
                  <div className="typeface-textbox">
                    <div className="typeface-texts">
                      <div className="dot-rt"></div>
                      <div className="dot-rb"></div>
                      <div className="dot-lt"></div>
                      <div className="dot-lb"></div>
                      <div className="typeface-text">
                        <p style={{fontSize:'32px', fontWeight:'600'}}>Happy to be here.</p>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          <div className="typeface-each-wrapper">
                <div className="typeface-detail-box">
                  <div className="typeface-describtion">
                      <h3>Level 4</h3>
                      <p> <b>For:</b> Body text in desktop and tablet format. This would contain information describing the details following its topic suggestion. For mobile format, this should be a good title.</p>
                      <div className="tags" id="typeface-tag">
                        <p className="tag">Size: 18px</p>
                        <p className="tag">Weight: 400</p>
                        <p className="tag">All Formats</p>
                      </div>
                  </div>
                </div>
                <div className="typeface-detail-box">
                  <div className="typeface-textbox">
                    <div className="typeface-texts">
                      <div className="dot-rt"></div>
                      <div className="dot-rb"></div>
                      <div className="dot-lt"></div>
                      <div className="dot-lb"></div>
                      <div className="typeface-text">
                        <p style={{fontSize:'18px', fontWeight:'400'}}>The reason that we are happy comes from you. You are the sunshine on our grateful minds.</p>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          <div className="typeface-each-wrapper">
                <div className="typeface-detail-box">
                  <div className="typeface-describtion">
                      <h3>Level 5</h3>
                      <p> <b>For:</b> Tags, chips, and footnotes in desktop and tablet format, and for mobile format, this should be a good body text.</p>
                      <div className="tags" id="typeface-tag">
                        <p className="tag">Size: 14px</p>
                        <p className="tag">Weight: 300</p>
                        <p className="tag">All Formats</p>
                      </div>
                  </div>
                </div>
                <div className="typeface-detail-box">
                  <div className="typeface-textbox">
                    <div className="typeface-texts">
                      <div className="dot-rt"></div>
                      <div className="dot-rb"></div>
                      <div className="dot-lt"></div>
                      <div className="dot-lb"></div>
                      <div className="typeface-text">
                        <p style={{fontSize:'14px', fontWeight:'300'}}>The reason that we are happy comes from you. You are the sunshine on our grateful minds.</p>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          <div className="typeface-each-wrapper">
                <div className="typeface-detail-box">
                  <div className="typeface-describtion">
                      <h3>Level 6</h3>
                      <p> <b>For:</b> Tags, chips, and footnotes for mobile format.</p>
                      <p>*Unless contained within a group, the margin should adjusted to 0.4em</p>
                      <div className="tags" id="typeface-tag">
                        <p className="tag">Size: 12px</p>
                        <p className="tag">Weight: 300</p>
                        <p className="tag">Mobile</p>
                      </div>
                  </div>
                </div>
                <div className="typeface-detail-box">
                  <div className="typeface-textbox">
                    <div className="typeface-texts">
                      <div className="dot-rt"></div>
                      <div className="dot-rb"></div>
                      <div className="dot-lt"></div>
                      <div className="dot-lb"></div>
                      <div className="typeface-text">
                        <p style={{fontSize:'12px', fontWeight:'300'}}>The reason that we are happy comes from you. You are the sunshine on our grateful minds.</p>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
      <div className='color-palette-describtion' style={{marginBottom:'40px', marginTop:'40px'}}>
        <h4 style={{fontWeight:'800', marginBottom:'20px'}}>Usage Summary Table</h4> 
      </div>
      <div className="typeface-table">
        <div className="typeface-table-each" style={{backgroundColor:'#CFCFCF'}}>
          <p>Level</p>
          <p>Desktop L</p>
          <p>Desktop M</p>
          <p>Tablet L</p>
          <p>Tablet M</p>
          <p>Mobile</p>
        </div>
        <div className="typeface-table-each">
          <p>1</p>
          <p>Header</p>
          <p>Accent Header</p>
          <p>Accent Header</p>
          <p>Accent Header</p>
          <p>-</p>
        </div>
        <div className="typeface-table-each">
          <p>2</p>
          <p>Header</p>
          <p>Header</p>
          <p>Header</p>
          <p>Header</p>
          <p>-</p>
        </div>
        <div className="typeface-table-each">
          <p>3</p>
          <p>Title</p>
          <p>Title</p>
          <p>Title</p>
          <p>Title</p>
          <p>Header</p>
        </div>
        <div className="typeface-table-each">
          <p>4</p>
          <p>Body</p>
          <p>Body</p>
          <p>Body</p>
          <p>Body</p>
          <p>Title</p>
        </div>
        <div className="typeface-table-each">
          <p>5</p>
          <p>Tag/Chip</p>
          <p>Tag/Chip</p>
          <p>Tag/Chip</p>
          <p>Tag/Chip</p>
          <p>Body</p>
        </div>
        <div className="typeface-table-each">
          <p>6</p>
          <p>-</p>
          <p>-</p>
          <p>-</p>
          <p>-</p>
          <p>Tag/Chip</p>
        </div>
      </div>
      {/* LAYOUT */}
      <div className="story" id="Research" style={{margin:'0 0 0 0', padding:'0 0 0 0', background:'none', display:'block'}}>
        <h3 id="ds-title">Layout and Components</h3>
        <div className="layout-compo-container"> 
            <div className='color-palette-describtion' style={{width:'700px', marginBottom:'40px'}}>
              <h4>Wide Screen Grid and Margin</h4>
              <p>How to apply: content should remain inside the 1em margin on both sides. Applying full width or 12/12 layout for features that require closer attention or banner. 6/12 width layouts are good for articles, and smaller features that do not require full reading. 4/12 width layouts are great as widget-like features, monitoring, or checking quickly for end users. </p>
            </div>
            <img src={hn_color_desktop_layout1} id="layout_format" alt="" />
            <div className='color-palette-describtion' style={{width:'700px', marginBottom:'40px'}}>
              <h4>Padding and Gutter</h4>
              <p>How to apply: each of the boxes should remain within 1em from the gutter from its sides. While related features and components should stay 1em from each other and 2em from separated components.</p>
            </div>
            <img src={hn_color_desktop_layout2} id="layout_format" alt="" />
            <div className='color-palette-describtion' style={{width:'700px', marginBottom:'40px'}}>
              <h4>Narrow Screens</h4>
              <p>How to apply: majority of features should be full width unless structured as widgets. Each block should remain at least 1em apart where padding remains 1/2em within the block. For vertical columns, apply 5 column-layout with a 2em margin on its sides.</p>
            </div>
            <img src={hn_color_desktop_layout3} style={{marginBottom:'20px'}} id="layout_format" alt="" />
            {/* <div className='color-palette-describtion' style={{width:'33%'}}>
              <h4>Basic Components</h4>
              <p>How to apply: how is this component reusable and what are the guidelines</p>
            </div> */}
            <div className='color-palette-describtion' style={{width:'700px', marginBottom:'40px'}}>
              <h4>Component Structure</h4>
              <p>How to apply: based on 16px default, use 1rem for margin and spaces between each elements. 2rem for the header and follow typography suggestions on font-size.</p>
            </div>
            <img src={hn_color_desktop_layout4} id="component_layout_format" alt="" />
        </div>
      </div>
      {/* MOBILE */}
      </div>
      <div className="story" style={{padding:'80px 138px 40px 138px'}}> 
          <ProjectNavigator 
            BlockTitle1="Cos"
            BlockDetail1="FinTech "
            to1="/cos-finance"
            BlockTitle2="AmíGo"
            BlockDetail2="Travel "
            to2="/amigo"
            BlockTitle3="Gauge"
            BlockDetail3="FinTech "
            to3="/gauge"
            />
      </div>
      <Footer />
    </div>
    <div className="mobile">
        <Navbar />
        <h2>Hello, Welcome to Hanover DS!</h2>
        <p>For more details, please view on desktop.</p>
        <Footer />
        {/* <CardMobile
          projectName="AmíGo"
          projectImg={amigo_banner}
          projectImgWidth="100%"
          projectImgBGColor="rgba(181,255,9,1)"
          projectDescribtion="Let's plan a trip well and hang out carefree together! AmíGo lets you control your trips quick and organized!"
          bannerChip1="Product Design"
          bannerChip2="12 Weeks"
          bannerChip3="UX Designer and researcher"
          bannerChip4="Mobile"
          challenges="Trip planning, packing, and budgeting together with the lack of tools collecting and sharing data in one spot can cause spontaneous and meticulous travelers anxiety and stress, resulting in undesired communication and trip management in group settings."
          roles="The major role I took in this project is product design and research. This includes creating design system, wireframe and prototype. Then, the research parts I took were surveying, interviewing, competitive analysis, and user flow for instance."
          tools="Figma | Google Suites | Miro | Adobe Illustrator | Adobe Photoshop & Credit to the team: Brann Khattiyanont and Aguilar, K., Bashar I.,Franchi M.,Scott S."
          bgIMG={amigo_groupset}
          blockATextColor="#424242"
          bgColor="rgba(181,255,9,1)"
          compATitle="42% faster connecting"
          compADetail="all them dots to stay inspired and informed for your trips!"
          textMargin="0 0 0 -50px"
          imgSet1={amigo_set1}
          imgSet2={amigo_set2}
          imgSet3={amigo_set3}
          set1Head="42% Faster to communicate"
          set1Detail="Stay synchronized with planning with your tripmates. Updated and fast to adjust, notify, and organize your trip data. Avoiding switching between platforms saves up to 22 seconds per action."
          set2Head="Stay on budget!"
          set2Detail="Intuitively easy to navigate with visual cues and real-time up-to-dated expense data for the whole group whenever you share something!"
          set3Head="Stay Inspired!"
          set3Detail="Together we are strong, and make the whole group stay on the same page with any dress code, theme, or trip inspiration."
          destination="https://www.figma.com/proto/ePAbClc3z7hLz3axIP618I/SI-699-Prototype?page-id=314%3A394&node-id=617-905&viewport=103%2C-599%2C0.15&t=nJW5KLLlgLrd8L52-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=617%3A905&show-proto-sidebar=1"
          bgcolor="rgb(0,106,255)"
          holder="Prototype"
        /> */}
    </div>
    </>
  )
}

export default ProjectHanover