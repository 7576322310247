import './App.css';
import ReactGA from 'react-ga4';

import { Routes, Route } from 'react-router-dom';
import Homepage from './components/Homepage';
import Aboupage from './components/Aboutpage';
import ProjectCos from './components/ProjectCos';
import ProjectAmigo from './components/ProjectAmigo';
import ProjectRacingSusan from './components/ProjectRacingSusan';
import ProjectKitchr from './components/ProjectKitchr';
import ProjectFoodFight from './components/ProjectFoodFight';
import ProjectLibrary from './components/ProjectLibrary';
import ProjectHanover from './components/ProjectHanover';
import ProjectCos_New from './components/ProjectCos_New';
import ProjectGauge from './components/ProjectGauge';

function App() {
  ReactGA.initialize('G-5RXZ8KYWW8');
  return (
    <>
    <div className="App">
      <Routes>
        <Route exact path = "/" element = {<Homepage />}></Route>
        <Route exact path = "/about" element = {<Aboupage />}></Route>
        <Route exact path = "/cos-finance" element = {<ProjectCos_New />}></Route>
        <Route exact path = "/amigo" element = {<ProjectAmigo />}></Route>
        <Route exact path = "/racing-susan" element = {<ProjectRacingSusan/>}></Route>
        <Route exact path = "/gauge" element = {<ProjectGauge/>}></Route>
        <Route exact path = "/kitchr" element = {<ProjectKitchr/>}></Route>
        <Route exact path = "/foodfight" element = {<ProjectFoodFight />}></Route>
        <Route exact path = "/library" element = {<ProjectLibrary />}></Route>
        <Route exact path = "/hanover" element = {<ProjectHanover />}></Route>
        <Route exact path = "/revisioned/cos" element = {<ProjectCos />}></Route>
      </Routes>
    </div>
    </>
  );
}

export default App;
