import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { scrollThere } from './functions/scrollThere';
// CSS
import './css/Main.css'
import './css/Projects.css'
import './css/Research.css'
import './css/Elements.css'
// COMPO
import HeroBanner from './elements/HeroBanner'
import Breadcrumb from './elements/Breadcrumb';
import ProjectBrief from './elements/ProjectBrief';
import ProjectComponentA from './elements/ProjectComponentA';
import ProjectComponentB from './elements/ProjectComponentB';
import ProjectComponentC from './elements/ProjectComponentC';
import ProjectComponentC2 from './elements/ProjectComponentC2';
import Project from './elements/ProjectComponentC';
import ProjectComponentE from './elements/ProjectComponentE';
import ReserchBlockA from './elements/ReserchBlockA';
import ReserchBlockB from './elements/ReserchBlockB';
import ReserchBlockC from './elements/ReserchBlockC';
import ReserchBlockD from './elements/ReserchBlockD';
import Footer     from './elements/Footer';
import ProjectNavigator from './elements/ProjectNavigator';
import CardMobile from './elements/CardMobile';
// IMG
import nl_home from './img/homepage/projectLibrary/BN-Top.png'
import Banner_Media from './img/homepage/projectLibrary/BN-Media.png'
import nl_front from './img/homepage/projectLibrary/BN-NatLib.png'
import Banner_ERead from './img/homepage/projectLibrary/BN-ERead.png'
import Banner_Ent from './img/homepage/projectLibrary/BN-Entrance.png'
import Banner_Diagram from'./img/homepage/projectLibrary/BN-Diagram.svg'

import ff_set1 from './img/homepage/projectFoodFight/ff_set1.png'
import ff_set2 from './img/homepage/projectFoodFight/ff_set2.png'

function ProjectLibrary() {

    // linear-gradient(82deg, rgba(255,255,255,1) 24%, rgba(86,134,214,1) 63%)

  window.scrollTo(0,0);
  return (
    <>
    <div className="desktop">
      <div id="project-top">
        <HeroBanner 
        projectTitle="Library System"
        textBlockMarginTop="120px"
        projectHeadline="This project built on top of my cap-stone interior architecture where I designed and rendered the context for our Library System."
        bannerImage={nl_home}
        bannerWidth="150%"
        bannerImgMargin="0 0 0 0px"
        HeroBGcolor="#494949"
        borderR="0.8em 0 0 0.8em"
        bannerChip1="Product & Interior Design"
        bannerChip2="2 Weeks"
        bannerChip3="Design Lead"
        bannerChip4="Tablet"
        bgColor="white"
        color="rgba(255,255,255,0.8)"
        tagBG="#494949"
        tagCl="white"
        spinner="none"
        />
      </div>
      <ProjectBrief 
          challenges="The discontinuity of library services both off and online, together with public opinion, makes them look inaccessible"
          roles="Design and research to create a platform where users can connect to the database for resources easily and entertaining"
          tools="Figma | Photoshop | Ilustrator | Sketch | Sketchup"
      />
      <ReserchBlockA
                researchAIMG={nl_front}
                resAWidth="100%"
                imgPadding="20px"
      />  
      <div className="nl-body">
      <ReserchBlockA
                BlockTitle="Scope of works"
                BlockADetail="The internal online platform for the library's services. This project is an extension to the original Interior Architecture renovation studies from my academic proposal."
                researchAIMG={Banner_Media}
                resAWidth="100%"
                imgPadding="20px"
      />  
      </div>
      <ReserchBlockA
                BlockTitle="SPONTANEOUS COLLABORATION"
                BlockADetail="More than 40% of users do not read. Major activities tend to be working in groups or individually"
                researchAIMG={Banner_ERead}
                resAWidth="100%"
                imgPadding="20px"
      />  
      <ReserchBlockA
                BlockTitle="STAY CONNECTED AND READ IN ANY FORMAT"
                BlockADetail="Power through your favorite books or works anywhere seamlessly with assistance from the library."
                researchAIMG={Banner_Ent}
                resAWidth="100%"
                imgPadding="20px"
      />  
          <div className="story" style={{padding:'80px 0 40px 0', margin:'0px 0 0 0'}}> 
          <ProjectNavigator 
            BlockTitle1="Cos"
            BlockDetail1="FinTech "
            to1="/cos-finance"
            BlockTitle2="AmíGo"
            BlockDetail2="Travel "
            to2="/amigo"
            BlockTitle3="Gauge"
            BlockDetail3="FinTech "
            to3="/gauge"
            />
          <Footer />
    </div>
    </div>
    <div className="mobile">
        <CardMobile 
          projectName="Library System"
          projectImg={nl_home}
          projectImgWidth="90%"
          projectImgBGColor="#494949"
          projectDescribtion="This project built on top of my cap-stone interior architecture where I designed and rendered the context for our Library System.."
          bannerChip1="Product & Interior Design"
          bannerChip2="2 Weeks"
          bannerChip3="Design Lead"
          bannerChip4="Tablet"
          challenges="The discontinuity of library services both off and online, together with public opinion, makes them look inaccessible"
          roles="Design and research to create a platform where users can connect to the database for resources easily and entertaining"
          tools="Figma | Photoshop | Ilustrator | Sketch | Sketchup"
          compATitle="Scope of works"
          compADetail="The internal online platform for the library's services. This project is an extension to the original Interior Architecture renovation studies from my academic proposal."
          textMargin="0 0 0 50px"
          bgColor="white"
          blockATextColor="#474747"
          imgSet1={Banner_Media}
          imgSet2={Banner_ERead}
          set1Head="SPONTANEOUS COLLABORATION"
          set1Detail="More than 40% of users do not read. Major activities tend to be working in group or individually."
          set2Head="STAY CONNECTED AND READ IN ANY FORMAT"
          set2Detail="Power through your favorite books or works anywhere seamlessly with assistance from the library."
          FigmaOrNo="none"
        />
    </div>
    </>
  )
}

export default ProjectLibrary