import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { scrollThere } from './functions/scrollThere';
// CSS
import './css/Projects.css'
import './css/Research.css'
// COMPO
import HeroBanner from './elements/HeroBanner'
import Breadcrumb from './elements/Breadcrumb';
import ProjectBrief from './elements/ProjectBrief';
import ProjectComponentA from './elements/ProjectComponentA';
import ProjectComponentA2 from './elements/ProjectComponentA2';
import ProjectComponentB from './elements/ProjectComponentB';
import ProjectComponentC from './elements/ProjectComponentC';
import ProjectComponentC2 from './elements/ProjectComponentC2';
import ProjectComponentE from './elements/ProjectComponentE';
import ReserchBlockA from './elements/ReserchBlockA';
import ReserchBlockB from './elements/ReserchBlockB';
import ReserchBlockC from './elements/ReserchBlockC';
import ReserchBlockD from './elements/ReserchBlockD';
import Footer     from './elements/Footer';
import ProjectNavigator from './elements/ProjectNavigator';
import CardMobile from './elements/CardMobile'

// IMG
import amigo_banner from './img/homepage/projectAmigo/amigo_banner.png'
import amigo_icon1 from './img/homepage/projectAmigo/amigo_Icon-trip.png'
import amigo_icon2 from './img/homepage/projectAmigo/amigo_Icon-budget.png'
import amigo_icon3 from './img/homepage/projectAmigo/amigo_Icon-fashion.png'
import amigo_icon4 from './img/homepage/projectAmigo/amigo_Icon-inspiration.png'
import amigo_icon5 from './img/homepage/projectAmigo/amigo_Icon-bookmark.png'
import amigo_icon6 from './img/homepage/projectAmigo/amigo_Icon-chats.png'
import amigo_c_a   from './img/homepage/projectAmigo/amigo_budget-page.png'
import amigo_groupset   from './img/homepage/projectAmigo/amigo_group-set.png'
import amigo_overall   from './img/homepage/projectAmigo/amigo_overall.png'
import amigo_research from './img/homepage/projectAmigo/amigo_research.png'
import amigo_flows from './img/homepage/projectAmigo/amigo_flows.png'
import amigo_layout from './img/homepage/projectAmigo/amigo-layout.png'
import amigo_set1 from './img/homepage/projectAmigo/amigo-feature1.png'
import amigo_set2 from './img/homepage/projectAmigo/amigo-feature2.png'
import amigo_set3 from './img/homepage/projectAmigo/amigo-feature3.png'

function ProjectAmigo() {

    // linear-gradient(82deg, rgba(255,255,255,1) 24%, rgba(86,134,214,1) 63%)

  window.scrollTo(0,0);
  return (
    <>
    <div className="desktop">
      <div id="project-top">
        <HeroBanner 
        projectTitle="AmíGo"
        projectHeadline="Let's plan a trip well and hang out carefree together! AmíGo lets you control your trips quickly and organized!"
        bannerImage={amigo_banner}
        bannerChip1="Product Design and Research"
        bannerChip2="12 Weeks"
        bannerChip3="Lead Design"
        bannerChip4="Mobile | ATM"
        bgColor="white"
        tagBG="rgba(181,255,9,1)"
        tagCl="rgb(37,37,37)"
        HeroBGcolor="linear-gradient(333deg, rgba(181,255,9,1) 0%, rgba(252,255,66,1) 100%"
        borderR="100em"
        spinner="none"
        HeroMargin="-70px"
        HeroEdge="40px"
        />
      </div>
      <Breadcrumb 
          top="project-top"
          section1="Introduction"
          section2="Product"
          section3="Prototype"
          section4="Research"
          section1Lable="Introduction"
          section2Lable="Product"
          section3Lable="Prototype"
          section4Lable="Research"
          currentPage="AmíGo"
      />
      <div className="stories">
          <div className="story" id="Introduction">
            <ProjectBrief 
            challenges="Trip planning, packing, and budgeting together with the lack of tools to collect and share data in one spot can cause spontaneous and meticulous travelers anxiety and stress, resulting in undesired communication and trip management in group settings."
            roles="The major role I took in this project was product design and research. This includes creating a design system, wireframe, and prototype. Then, the research parts I took were surveying, interviewing, competitive analysis, and user flow for instance."
            tools="Figma | Google Suites | Miro | Adobe Illustrator | Adobe Photoshop & Credit to the team: Brann Khattiyanont and Aguilar, K., Bashar I., Franchi M., Scott S."
            />
          </div>
          <div className="story" id="Product">
            <div className="amigo-icons">
                <div className="amigo-icon">
                  <img src={amigo_icon1} alt="" />
                  <p>You can now manage who’s in for which events and their details!</p>
                </div>
                <div className="amigo-icon">
                  <img src={amigo_icon2} alt="" />
                  <p>Track how you and your group spent to avoid overspending.</p>
                </div>
                <div className="amigo-icon">
                  <img src={amigo_icon3} alt="" />
                  <p>Now you can share ideas of what to wear for a better group photo!</p>
                </div>
                <div className="amigo-icon">
                  <img src={amigo_icon4} alt="" />
                  <p>Spice up your trip with suggestions pulled from trusted API!</p>
                </div>
                <div className="amigo-icon">
                  <img src={amigo_icon5} alt="" />
                  <p>Collections of ideas are well organized within one place for all to check out!</p>
                </div>
                <div className="amigo-icon">
                  <img src={amigo_icon6} alt="" />
                  <p>Keep all in the loop, you can also ask Ami, our AI Chatbot, for more details. </p>
                </div>
            </div>
            <ProjectComponentC2
                logo={amigo_groupset}
                compBTitle="42% faster connecting"
                compBDetail="all the dots to stay inspired and informed for your trips!"
                BGRoundColor="#c8ff00"
                IMGMargin="auto auto auto 100px"
            />
          </div>
          <div className="story" id="Prototype">
              <ProjectComponentE
              figma="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fe0A5rsR2EDiLjcNUDC5oKK%2FUntitled%3Fpage-id%3D0%253A1%26node-id%3D516-14483%26viewport%3D-522%252C421%252C0.1%26t%3DbG5TnNONnc8WSW5H-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26starting-point-node-id%3D27%253A141%3D1%26hide-ui%3D1"
              image={amigo_overall}
              imgWidth="120%"
              prototypeColor="#f6ffa0"
              />
          </div>
          <div className="story" id="Research">
              <div className="v-text-container">
                  <h2 id="proto-v-text" style={{color:'#999999', marginTop:'-80px'}}>UX Research</h2>
              </div>
              <div className="ux-research">
                <div className="ux-r-intro">
                    <p>To come up with solutions, we progressed through iterations of analysis which can be divided into 3 steps. Ideas and opinions, Interpretation, and Integration.</p>
                </div>

                <ReserchBlockB 
                BlockTitle1="Surveys"
                BlockTitle2="Interviews"
                BlockTitle3="Cultural Probes"
                BlockDetail1="After brainstorming sessions, we polled 21 questions regarding users aged 20 - 35 who preferred traveling. The survey is designed to learn about their experiences; including demographics, travel habits, and concerns that they have."
                BlockDetail2="We then designed the questions for in-person or online interviews to find the types of travelers, spontaneous planners, or rigorous planners. We then asked them as specifically as possible about the last trip, and they went into detail about what we needed to solve."
                BlockDetail3="On-site logging experiences we can truly learn what users did during their trip. We asked our participants to note and photograph their logs. Then, we scrutinize their causes and actions to find the motivations and setbacks that can be improved with UX processes."
                />
                <ReserchBlockA
                BlockTitle="Identify Opportunities"
                researchAIMG={amigo_research}
                resAWidth="100%"
                />
                <div className="amigo-finding">
                  <div id="amigo-finding-no">
                    <h4>1</h4>
                    <p>71% percent of our surveys pointed out that <b>itinerary planning is a big issue</b> on their trips, while <b>59% worried about budgets</b> while traveling.</p>
                  </div>
                  <div id="amigo-finding-no">
                    <h4>2</h4>
                    <p>About 50 people from 75 participants planned their trip shortly before the trips started. This leads to our finding that <b>travelers get exhausted from their research parts.</b></p>
                  </div>
                  <div id="amigo-finding-no">
                    <h4>3</h4>
                    <p>One significant problem is that <b>travelers rely on multiple platforms to complete tasks.</b> These include communication, research, planning, and budgeting.</p>
                  </div>
                </div>
                <ReserchBlockA
                BlockTitle="App Architecture"
                researchAIMG={amigo_flows}
                resAWidth="100%"
                imgPadding="20px"
                BGColor="white"
                />

                <details style={{marginLeft:'80px'}}>
                  <summary>References</summary>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Image Club: Unsplash.com, Valentin bounjour
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Image Skateboard: Unsplash.com, Max Tarkhov
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Image Dress1: Unsplash.com, Erwi              
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Image Dress2: Unsplash.com, Tanya Layko               
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Image Stanford: Unsplash.com, Ashim D'Silva                
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Image Art wall: Unsplash.com, Simon Zhu             
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Image Art wall: Unsplash.com, Simon Zhu</p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Image SF Piers: Unsplash.com, Abhi Verma</p>
                </details> 
              </div>
          </div>
      </div>
      <div className="story" id="Testing-Wireframes" style={{color:"white"}}> 
              <div className="v-text-container">
                <h2 id="proto-v-text" style={{color:'#999999', marginTop:'-80px'}}>Wireframes</h2>
              </div>
              <div className="ux-research"> 
                  <ReserchBlockA
                    BlockTitle=""
                    researchAIMG={amigo_layout}
                    resAWidth="100%"
                    imgMargin="-60px 0 0 0"
                  />
              </div>
        </div>
        <div className="story" style={{padding:'80px 138px 40px 138px'}}> 
          <ProjectNavigator 
            BlockTitle1="Cos"
            BlockDetail1="FinTech "
            to1="/cos-finance"
            BlockTitle2="AmíGo"
            BlockDetail2="Travel "
            to2="/amigo"
            BlockTitle3="Gauge"
            BlockDetail3="FinTech "
            to3="/gauge"
            />
      </div>
      <Footer />
    </div>
    <div className="mobile">
        <CardMobile
          projectName="AmíGo"
          projectImg={amigo_banner}
          projectImgWidth="100%"
          projectImgBGColor="rgba(181,255,9,1)"
          projectDescribtion="Let's plan a trip well and hang out carefree together! AmíGo lets you control your trips quick and organized!"
          bannerChip1="Product Design"
          bannerChip2="12 Weeks"
          bannerChip3="UX Designer and researcher"
          bannerChip4="Mobile"
          challenges="Trip planning, packing, and budgeting together with the lack of tools to collect and share data in one spot can cause spontaneous and meticulous travelers anxiety and stress, resulting in undesired communication and trip management in group settings."
          roles="The major role I took in this project was product design and research. This includes creating a design system, wireframe, and prototype. Then, the research parts I took were surveying, interviewing, competitive analysis, and user flow for instance."
          tools="Figma | Google Suites | Miro | Adobe Illustrator | Adobe Photoshop & Credit to the team: Brann Khattiyanont and Aguilar, K., Bashar I.,Franchi M.,Scott S."
          bgIMG={amigo_groupset}
          blockATextColor="#424242"
          bgColor="rgba(181,255,9,1)"
          compATitle="42% faster connecting"
          compADetail="all them dots to stay inspired and informed for your trips!"
          textMargin="0 0 0 -50px"
          imgSet1={amigo_set1}
          imgSet2={amigo_set2}
          imgSet3={amigo_set3}
          set1Head="42% Faster to communicate"
          set1Detail="Stay synchronized with planning with your tripmates. Updated and fast to adjust, notify, and organize your trip data. Avoiding switching between platforms saves up to 22 seconds per action."
          set2Head="Stay on budget!"
          set2Detail="Intuitively easy to navigate with visual cues and real-time up-to-dated expense data for the whole group whenever you share something!"
          set3Head="Stay Inspired!"
          set3Detail="Together we are strong, and make the whole group stay on the same page with any dress code, theme, or trip inspiration."
          destination="https://www.figma.com/proto/ePAbClc3z7hLz3axIP618I/SI-699-Prototype?page-id=314%3A394&node-id=617-905&viewport=103%2C-599%2C0.15&t=nJW5KLLlgLrd8L52-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=617%3A905&show-proto-sidebar=1"
          bgcolor="rgb(0,106,255)"
          holder="Prototype"
        />
    </div>
    </>
  )
}

export default ProjectAmigo