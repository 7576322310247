import React from 'react'
import Navbar from './elements/Navbar'

import './css/Project_version_2.css'

import HeroImage from './elements/HeroImage'
import Breadcrumb from './elements/Breadcrumb'
import ProjectComponentE from './elements/ProjectComponentE'
import Footer from './elements/Footer'
import ProjectNavigator from './elements/ProjectNavigator'
import ReserchBlockD from './elements/ReserchBlockD'
import CardMobile from './elements/CardMobile';

import gauge_banner from '../components/img/homepage/projectGauge/GG_Home.png'
import gauge_credit from '../components/img/homepage/projectGauge/GG_Credit.png'
import gauge_inventory from '../components/img/homepage/projectGauge/GG_Inventory.png'
import gauge_file from '../components/img/homepage/projectGauge/GG_File.png'
import cos_spending from '../components/img/homepage/projectCos/Version2/cos-spending.png'
import cos_friends from '../components/img/homepage/projectCos/Version2/cos-friends.png'
import cos_tips from '../components/img/homepage/projectCos/Version2/cos-tips.png'
import cos_rewards from '../components/img/homepage/projectCos/Version2/cos-rewards.png'
import cos_warning from '../components/img/homepage/projectCos/Version2/cos-warning.png'
import cos_colors from '../components/img/homepage/projectCos/Version2/cos-colors.png'
import cos_ads from '../components/img/homepage/projectCos/Version2/cos-ads.png'
import cos_typos from '../components/img/homepage/projectCos/Version2/cos-typos.png'
import gauge_art from '../components/img/homepage/projectGauge/GG_DS.png'
import gauge_prototype from '../components/img/homepage/projectGauge/GG_All.png'
import cos_bigpicture from '../components/img/homepage/projectCos/Version2/cos_identify_diagram.svg'
import gauge_ux from '../components/img/homepage/projectGauge/GG_UX.svg'
import gauge_icon1 from '../components/img/homepage/projectGauge/icon1.png'
import gauge_icon2 from '../components/img/homepage/projectGauge/icon2.png'
import gauge_icon3 from '../components/img/homepage/projectGauge/icon3.png'
import cos_sketch1 from './img/homepage/projectCos/cos_scenario1.png'
import cos_sketch2 from './img/homepage/projectCos/cos_scenario2.png'
import cos_sketch3 from './img/homepage/projectCos/cos_scenario3.png'
import cos_arch1 from './img/homepage/projectCos/cos-ux-dir1.svg'
import cos_arch2 from './img/homepage/projectCos/cos-ux-dir2.svg'
import cos_wireframe1 from './img/homepage/projectCos/cos-wireframes1.png'
import cos_wireframe2 from './img/homepage/projectCos/cos-wireframes2.png'
import gauge_wireframes from '../components/img/homepage/projectGauge/GG_wireframes.png'

import gauge_m1 from '../components/img/homepage/projectGauge/set1.png'
import gauge_m2 from '../components/img/homepage/projectGauge/set2.png'
import gauge_m3 from '../components/img/homepage/projectGauge/set3.png'

function ProjectGauge() {
   window.scrollTo(0,0);
  return (
    <>
    <div className="desktop">
    <Navbar />
    <div className="project-template" id="project-top">
        <div className="pb-container">
            <div className="pb-banner-prototype">
                <HeroImage 
                bannerImage={gauge_banner}
                borderR="100em 0 0 100em"
                HeroBGcolor="#FE3F42"
                spinner="none"
                HeroMargin="8.5%"
                HeroEdge="0px"
                HeroEdgeL="0px"
                bannerWidth="150%"
                bannerImgMargin="-200px 200px 0 -80px"
                />
            </div>
            <div className="pb-banner-container">
                <div className="pb-banner-texts">
                    <h1>Gauge</h1>
                    <p style={{marginTop:'0'}}>Gauge helps small and medium businesses manage their finances more intuitively and effectively by <b>offering simple tools which address problems</b> often overlooked by a complicated software designed for tech-savvy users.</p>
                    <div className="pb-chips-wrap">
                        <p className="pb-chip" id="gauge-chip">12 Weeks</p>
                        <p className="pb-chip" id="gauge-chip">Lead Design</p>
                        <p className="pb-chip" id="gauge-chip">Desktop</p>
                    </div>
                </div>
                <div className="pb-briefs" id="Introduction">
                    <div className="pb-brief-wrap">
                        <div className="pb-brief-ea">
                            <h4 id="pb-brief-ea-t">Challenges</h4>
                            <p id="pb-brief-ea-t">
                            <b>Digital and finance literacy and multiple team account </b> lead the misperception on adopting new SaaS product despite its efficiency.    
                            </p>
                        </div>
                        <div className="pb-brief-ea">
                            <h4 id="pb-brief-ea-t">Big Ideas</h4>
                            <p id="pb-brief-ea-t">
                            Streamline workflows with <b>flexible, cross-formating, artificial intelligence, and simplified functions</b> designed for a broad range of small and medium business owners, encouraging them to explore and discover solution form over product.
                            </p>
                        </div>
                        <div className="pb-brief-ea">
                            <div className="pb-chips-wrap" style={{marginTop:'0', marginBottom:'0'}}>
                                <p className="pb-chip" id="cos-chip-tool">Figma</p>
                                <p className="pb-chip" id="cos-chip-tool">Illustrator</p>
                                <p className="pb-chip" id="cos-chip-tool">Photoshop</p>
                            </div>
                            <div className="pb-chips-wrap">
                                <p className="pb-chip" id="cos-chip-tool">Miro</p>
                                <p className="pb-chip" id="cos-chip-tool">MS Office</p>
                                <p className="pb-chip" id="cos-chip-tool">Notebook</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="pd-container">
            <div className="pd-scope-container" id="gauge-chip">
                <div className="pd-im">
                    <h3 id="pd-im-st">25%+</h3>
                    <p><b>Faster to manage simple tasks</b></p>
                </div>
                <div className="pd-im">
                    <h3 id="pd-im-st">3 in 1</h3>
                    <p><b>Planning, Managing, Projecting</b></p>
                </div>
                <div className="pd-im">
                    <h3 id="pd-im-st">8+</h3>
                    <p><b>important features for finance improvement</b></p>
                </div>
            </div>
            <div className="pd-overview-container" id="Product" style={{textAlign:'center', justifyContent:'center'}}>
                <img src={gauge_credit} style={{marginTop:'40px', width:'90%', marginLeft:'auto', marginRight:'auto'}} id="big_banner" alt="" />
            </div>
            <div className="pd-premium-container">
                <div className="pd-p-t-top">
                    <h2>Totally in your control!</h2>
                    <p>Don't worry about multiple file formats—whether it's from your POS, spreadsheets, or notes, we've got you covered! Gauge is designed to handle the complex tasks that keep you from focusing on your business growth.</p>
                </div>
                <img src={gauge_file} id="middle-img" alt="" />
                <div className="pd-p-t-bottom">
                    <div className="pd-p-t-b-l">
                        <h3>For a team player</h3>
                        <p>Assign your finance workload to your team with different accessibilities. Fix issues faster With asynchronous methods with our product!</p>
                    </div>
                    <div className="pd-p-t-b-m">
                        <h3>On Point & On Track</h3>
                        <p>Keep you notified of your financial health easily from our dashboard systems. Systematically allow you to evaluate tasks with AI suggestions!</p>
                    </div>
                    <div className="pd-p-t-b-r">
                        <h3>Credit & Partners</h3>
                        <p>With more credit to increase your cash flow over 60%, good partners can make it happen!</p>
                    </div>
                </div>
            </div>
            <div className="pd-branding-conmtainer">
                {/* <h2 style={{color:'white'}}>Visual and Interface</h2> */}
                <div className="pd-b-wrap">
                    <img src={gauge_art} style={{width:'100%'}} alt="" />                                                
                </div>
            </div>
        </div>
        <div className="p-prototype-container">
            <div className="story" id="Prototype">
                    <div className="p-pt-wrap" style={{minHeight:'660px'}}>
                        <div className="p-pt-wrap-l">
                            <h2 id='title-h2' style={{width:'100%', marginBottom:'36px'}}>Check it out!</h2>
                            <a  className="clickable" id="gauge-click" href="https://www.figma.com/proto/dJOOR6CIG7Jpxiv4D1r0Sl/Gauge?page-id=302%3A7763&node-id=302-8187&node-type=frame&viewport=450%2C309%2C0.29&t=YyRCENcaQw0o8mUB-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=302%3A8187" target="_blank" rel="noopener noreferrer">Prototype</a>
                        </div> 
                        <div className="p-pt-wrap-r">
                            <img src={gauge_prototype} style={{width:'100%'}} alt="" />
                        </div>
                    </div>
            </div>
        </div>
        <div className="pr-container">
            <div className="pd-scope-container" style={{marginTop:'0px'}} id="gauge-chip">
                <div className="pd-im">
                    <h3 id="pd-im-st">8+</h3>
                    <p><b>Businesses interviews for the accuracy of the project.</b></p>
                </div>
                <div className="pd-im">
                    <h3 id="pd-im-st">Agile</h3>
                    <p><b>Testing from qualitative to behavior study</b></p>
                </div>
                <div className="pd-im">
                    <h3 id="pd-im-st">2+</h3>
                    <p><b>Iteration for better flow of use.</b></p>
                </div>
            </div>
            <div className="pr-mapping-container" id="Wireframe">
                <h2 id="title-h2">So, how do we get here?</h2>
            </div>
            <div className="pr-research-wrap">
                <div className="pr-r-ea">
                    <div className="pr-r-ea-t">
                    <h3 id="research-header">"Rinse and Repeat "</h3>
                    <p>To come up with solutions, we progressed through iterations of analysis which can be divided into 3 steps. Ideas and opinions, Interpretation, and Integration.</p>
                    </div>
                    <div className="pr-r-ea-img">
                        <img src={gauge_ux} style={{width:'100%', marginTop:'-100px'}} alt="" />
                    </div>
                </div>
                <div className="pr-r-ea-3col" style={{marginTop:'40px'}}>
                    <div className="pr-r-ea-col">
                        <div className="pr-r-ea-col-img">
                            <img src={gauge_icon1} alt="" style={{width:'34%'}} id="rs-icon"/>
                        </div>
                        <div className="pr-r-ea-col-t">
                            <h3 id="research-header">"SaaS is viwed to require higher literacy to use"</h3>
                            <p>Businesses misunderstood that improving their organization's financial and digital literacy will be difficult and time-consuming . This is why they avoid adopting new products.</p>
                        </div>
                    </div>
                    <div className="pr-r-ea-col">
                        <div className="pr-r-ea-col-img">
                            <img src={gauge_icon2} style={{width:'40%'}} alt="" id="rs-icon"/>
                        </div>
                        <div className="pr-r-ea-col-t">
                            <h3 id="research-header">"Stay informed for business owner's best interest"</h3>
                            <p style={{marginTop:'8.5px'}}>It is important to keep users notified on the latest update to leave an adequate time for business planning and effective finance decistion. </p>
                        </div>
                    </div>
                    <div className="pr-r-ea-col">
                        <div className="pr-r-ea-col-img">
                            <img src={gauge_icon3} style={{width:'34%'}} alt="" id="rs-icon"/>
                        </div>
                        <div className="pr-r-ea-col-t">
                            <h3 id="research-header">"Simplicity attracts entry level businesses"</h3>
                            <p>As the functions become more complex and technical, we need to make the product as simple and intuitive as much as possible regardless of of how prevalence it is.</p>
                        </div>
                    </div>
                </div>
                {/* <details style={{marginBottom:'120px'}}>
                  <summary>References</summary>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Gravier, E. (2024, January 24). Best Banks And Credit Unions For Mobile Banking of 2024. CNBC. Retrieved January 30, 2024, from https://www.cnbc.com/select/best-banks-credit-unions-for-mobile-banking
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Underwood, J., & Aldrich, E. (2023, December 13). Consumer Banking Trends and Statistics 2024. Forbes. Retrieved January 30, 2024, from https://www.forbes.com/advisor/banking/banking-trends-and-statistics
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • White, A. (2024, January 2). Why Millennials, Gen Z Are Likely to Use Mobile Banking Apps. CNBC. Retrieved January 30, 2024, from https://www.cnbc.com/select/why-millennials-gen-z-use-mobile-banking-apps                  
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • McKinsey & Company. (2023, October 10). McKinsey's Global Banking Annual Review 2023. McKinsey. Retrieved January 30, 2024, from https://www.mckinsey.com/industries/financial-services/our-insights/global-banking-annual-review                  
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Lake, R., & Foreman, D. (2021, April 5). Increase In Digital Banking Raises Consumer Data Privacy Concerns: How To Protect Yourself. Forbes. Retrieved February 2, 2024, from https://www.forbes.com/advisor/banking/digital-banking-consumer-data-privacy-concerns                  
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Kreger, A. (2020, May 15). YouTube: Home. Retrieved February 2, 2024, from https://www.forbes.com/sites/forbesbusinesscouncil/2023/11/14/digital-banking-design-how-to-take-an-ecosystem-driven-approach/?sh=517add7ab2c1                   
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Abbott, M. (2024, January 16). Forbes. Retrieved February 2, 2024, from https://www.forbes.com/sites/michaelabbott/2024/01/16/the-top-10-banking-trends-for-2024--the-age-of-ai/?sh=3d88b2924510 
                  </p>
                </details>  */}
            </div>
            {/* <div className="pr-research-users-wrap" style={{paddingBottom:'120px'}}>
                <h2 id="title-h2" style={{textAlign:'center'}}>Users First</h2>
                <div className="pr-r-ea">
                    <div className="pr-r-ea-t">
   
                        <h3 id="research-header">"Data control and communication need fixing"</h3>

                        <p><b>Data and Connection Issues.</b> Most users rely on their data on banking mobile apps, which could be a problem when the internet is down.</p>
                        <p><b>Communication Issues.</b> The communication issues are either from users or the systems and database.</p>
                        <p><b>Lack help Issues</b> Users' problems do not constituent system's help center enough which can cause users' frustration and negativity.</p>
                    </div>
                    <div className="pr-r-ea-img" style={{padding:'0px'}}>
                        <img src={cos_sketch1} style={{width:'100%', borderRadius:'24px 24px 0 0'}} alt="" />
                        <img src={cos_sketch2} style={{width:'100%'}} alt="" />
                        <img src={cos_sketch3} style={{width:'100%', borderRadius:'0 0 24px 24px'}} alt="" />
                    </div>
                </div>
            </div> */}
            <div className="story" id="Testing-Wireframes" style={{backgroundColor:'#282c2f', paddingBottom:'120px'}}> 
            <div className="ux-r">
                <h2 id="title-h2" style={{textAlign:'center', marginBottom:'120px', marginTop:'0', color:'white'}}>Wireframes and Testing</h2>
                <div className="ux-research"> 
                {/* <h3 style={{textAlign:'center', color:'white', marginBottom:'42px'}}>option A</h3>
                <ReserchBlockD 
                BlockIMG1={cos_arch1}
                BlockIMG2={cos_wireframe1}
                />
                <h3 style={{textAlign:'center', color:'white', marginBottom:'42px'}}>option B</h3>
                <ReserchBlockD 
                BlockIMG1={cos_arch2}
                BlockIMG2={cos_wireframe2}
                /> */}
                <h3 style={{textAlign:'center', color:'white', marginBottom:'42px'}}>"Power of Iterations"</h3>
                <img src={gauge_wireframes} style={{width:'100%'}} alt="" />
                </div>
            </div>
        </div>
        <div className="pd-scope-container" style={{marginTop:'0'}} id="gauge-chip">
                <div className="pd-im">
                    <h3 id="pd-im-st">50%</h3>
                    <p><b>Cleaner and more intuitive to use than version 1.</b></p>
                </div>
                <div className="pd-im">
                    <h3 id="pd-im-st">Better</h3>
                    <p><b>for broader SMBs audiences.</b></p>
                </div>
                <div className="pd-im">
                    <h3 id="pd-im-st">Adding</h3>
                    <p><b>Direct B2B connection, enhancing businesses' convenience!</b></p>
                </div>
            </div>
        </div>
        {/* <div className="pr-mapping-container">
                <h2 id="title-h2">How about we get in touch?</h2>
                <div className="clickable-wrap">
                    <a className="clickable">Email Brann</a>
                    <a className="clickable">LinkedIn</a>
                </div>
        </div> */}
    </div>
    <div className="story" style={{padding:'80px 138px 40px 138px'}}> 
          <ProjectNavigator 
          BlockTitle1="Cos"
          BlockDetail1="FinTech "
          to1="/cos-finance"
          BlockTitle2="AmíGo"
          BlockDetail2="Travel "
          to2="/amigo"
          BlockTitle3="Gauge"
          BlockDetail3="FinTech "
          to3="/gauge"
          />
    </div>
    <Breadcrumb 
                top="project-top"
                section1="Introduction"
                section2="Product"
                section3="Prototype"
                section4="Wireframe"
                section1Lable="Introduction"
                section2Lable="Product"
                section3Lable="Prototype"
                section4Lable="Research"
                currentPage="gauge"
                topScroll="460p"
            />
    <Footer />
    </div>
    <div className="mobile">
    <CardMobile 
          projectName="Gauge"
          projectImg={gauge_banner}
          projectImgWidth="100%"
          projectImgBGColor="#FE3F42"
          projectDescribtion="Gauge helps small and medium businesses manage their finances more intuitively and effectively by offering simple tools which address problems  often overlooked by a complicated software designed for tech-savvy users."
          bannerChip1="Product Design"
          bannerChip2="12 Weeks"
          bannerChip3="Lead Design"
          bannerChip4="Desktop"
          challenges="Digital and finance literacy and multiple team account lead the misperception on adopting new SaaS product despite its efficiency."
          roles="Indentify and conducting user research, analyze, and product design"
          tools="Figma | Google Suites | Miro | Adobe Illustrator | Adobe Photoshop | Sketchbook and Pen"
          bgIMG={gauge_credit}
          bgColor=""
          compATitle=""
          compADetail=""
          textMargin="0 0 0 -50px"
          imgSet1={gauge_m1}
          imgSet2={gauge_m2}
          imgSet3={gauge_m3}
          set1Head="For a team player"
          set1Detail="Assign your finance workload to your team with different accessibilities. Fix issues faster With asynchronous methods with our product!"
          set2Head="On Point & On Track"
          set2Detail="Keep you notified of your financial health easily from our dashboard systems. Systematically allow you to evaluate tasks with AI suggestions!"
          set3Head="Credit & Partners"
          set3Detail="With more credit to increase your cash flow over 60%, good partners can make it happen!"
          destination="https://www.figma.com/proto/dJOOR6CIG7Jpxiv4D1r0Sl/Gauge?page-id=302%3A7763&node-id=302-8187&node-type=frame&viewport=450%2C309%2C0.29&t=YyRCENcaQw0o8mUB-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=302%3A8187"
          bgcolor="rgb(0,106,255)"
          holder="Prototype"
        />
    </div>
    </>
  )
}

export default ProjectGauge